import api from "@api";
import router from "@/router";

export default {
    state: {
        unitList: [],
        unitNames: [],
        filterVal: "",
        helloSub: "hello sub",
        filterDto: {
            semesterId: "",
            yearId: "",
            facultyId: "",
            universityId: "",
            search: "",
        },
        unitDto: {
            name: "",
            imageUrl: "",
            tetcherName: "",
            subjectName: "",
            subjectId: "",
            isFree: false,
            semesterId: "",
            yearId: "",
            departmentId: "",
            universityId: "",
            facultyId: "",
        },
        DetailsUnit: {
            id: "",
            name: "",
            semesterName: "",
            yearName: "",
            facultyName: "",
            departmentName: "",
            universityName: "",
            imageUrl: "",
            isFree: true,
            chaptersCount: 0,
            chapters: [
                {
                    id: "",
                    name: "",
                    lessonCount: 0,
                    studentCount: 0,
                    dateCreated: "",
                },
            ],
        },
        url: "",
    },
    getters: {},
    mutations: {
        Get_Unit_List(state, payload) {
            console.log("dsfsdf", payload);
            state.unitList = { ...payload };
        },
        Get_Unit_Names(state, payload) {
            console.log("dsfsdf", payload);
            state.unitNames = payload;
        },
        Reset_Unit(state) {
            state.unitDto = {
                name: "",
                imageUrl: "",
                subjectId: "",
                isFree: false,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            };
        },
        Update_Unit(state, payload) {
            if (payload.id !== null) {
                state.unitDto = { ...payload };
            } else state.unitList.units.push(payload);
        },
        Add_File(state, payload) {
            state.url = payload;
        },
        Get_Details_Unit(state, payload) {
            state.DetailsUnit = { ...payload };
        },
        Empty(state) {
            console.log("Empty",state);
        
        },
    },
    actions: {
        getUrl(state) {
            return state.url;
        },
        addFile({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Empty");
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addFileUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_File", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        getUnitList({ commit }, payload) {
            api.get(
                `Unit/Dash/GetAll`,
                ({ data }) => {
                    commit("Get_Unit_List", data);
                },
                () => {},
                {
                    params: { ...payload },
                }
            );
        },
        getUnitNames({ commit },tab) {
            api.get(
                `Unit/Dash/GetAllNames?StudentType=${tab ? tab : 0}`,
                ({ data }) => {
                    commit("Get_Unit_Names", data);
                },
                () => {}
            );
        },
        updateUnit({ commit }, payload) {
            api.post(
                "Unit/Dash/Upsert",
                payload,
                ({ data }) => {
                    commit("Update_Unit", data);
                },
                {
                    success: "تمت العملية   بنجاح",
                    error: "حدث خطا يرجى إعادة المحاوولة ",
                }
            );
        },
        getUnitDetails({ commit }, id) {
            api.get(`Unit/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Unit", data);
            });
        },
        deleteUnit({ commit }, ids) {
            api.delete(
                `Unit/Dash/Delete `,
                () => {
                    commit("deleteUnits", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف",
                    success: "تم الحذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
