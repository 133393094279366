import api from "@api";
import router from "@/router";

export default {
    state: {
        chapterList: [],
        filterVal: "",
        helloSub: "hello sub",
        filterDto: {
            semesterId: "",
            yearId: "",
            SubjectId: "",
            facultyId: "",
            universityId: "",
            search: "",
        },
        chapterDto: {
            name: "",
            id: "",
            teacherId: "",
            isFree: false,
            unitId: "",
        },
        Detailschapter: {
            id: "",
            name: "",
            semesterName: "",
            yearName: "",
            facultyName: "",
            departmentName: "",
            universityName: "",
            teacherId: "",
            imageUrl: "",
            isFree: true,
            chaptersCount: 0,
            chapters: [
                {
                    id: "",
                    name: "",
                    lessonCount: 0,
                    studentCount: 0,
                    dateCreated: "",
                },
            ],
        },
        url: "",
        columns: [
            {
                label: " اسم القسم ",
                field: "departmentName",
            },
            {
                label: "  الجامعة ",
                field: "universityName",
            },
            {
                label: "  الكلية ",
                type: "facultyName",
                field: "facultyName",
            },
            {
                label: "اسم المادة ",
                type: "subjectName",
                field: "subjectName",
            },
            {
                label: "اسم الوحدة ",
                type: "unitName",
                field: "unitName",
            },
            {
                label: "عدد الدروس ",
                type: "lessonCount",
                field: "lessonCount",
            },

            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
        newColumns: [
            {
                label: " اسم القسم ",
                field: "name",
            },
            {
                label: "  المرحلة ",
                field: "universityName",
            },
            {
                label: "  الصف ",
                type: "departmentName",
                field: "departmentName",
            },
            {
                label: "الفصل ",
                type: "semesterName",
                field: "semesterName",
            },
            {
                label: "المادة ",
                type: "subjectName",
                field: "subjectName",
            },
            {
                label: " الوحدة ",
                type: "unitName",
                field: "unitName",
            },
            {
                label: "عدد الدروس ",
                type: "lessonCount",
                field: "lessonCount",
            },

            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    },
    getters: {},
    mutations: {
        Get_chapter_List(state, payload) {
            console.log("dsfsdf", payload);
            state.chapterList = payload;
        },
        Reset_chapter(state) {
            state.chapterDto = {
                name: "",
                imageUrl: "",
                subjectId: "",
                isFree: false,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            };
        },
        Update_chapter(state, payload) {
            if (payload.id !== null) {
                state.chapterDto = { ...payload };
            } else state.chapterList.chapters.push(payload);
        },
        Add_File_chapter(state, payload) {
            state.url = payload;
            console.log("pppppppppp", payload);
        },
        Get_Details_chapter(state, payload) {
            state.Detailschapter = { ...payload };
        },
        deletechapters(state, payload) {
            state.chapterList = state.chapterList.filter(
                (el) => !payload.includes(el.id)
            );
        },
    },
    actions: {
        getUrl(state) {
            return state.url;
        },
        addFileChapter({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_File_chapter", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        getchapterList({ commit }, payload) {
            api.get(
                `Chapter/Dash/GetAll?StudentType=${
                    payload.studentType ? payload.studentType : 0
                }`,
                ({ data }) => {
                    commit("Get_chapter_List", data);
                },
                () => {},
                {
                    params: { ...payload },
                }
            );
        },
        updatechapter({ commit }, payload) {
            api.post(
                "Chapter/Dash/Upsert",
                payload,
                ({ data }) => {
                    commit("Update_chapter", data);
                },
                {
                    success: "تمت العملية   بنجاح",
                    error: "حدث خطا يرجى إعادة المحاوولة ",
                }
            );
        },
        getchapterDetails({ commit }, id) {
            api.get(`Chapter/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_chapter", data);
            });
        },
        deletechapter({ commit }, ids) {
            api.delete(
                `Chapter/Dash/Delete`,
                () => {
                    commit("deletechapters", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف",
                    success: "تم الحذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
